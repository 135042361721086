<template>
  <div>
    <Header />
    <body class="body-experience">
      <section class="main-container pb-5" style="padding-top:150px">
        <div class="container top-container">
          <div class="content">
            <div class="container" v-if="bannerTop">
              <!-- Banner -->
              <div
                v-for="(banner, key) in bannerTop"
                :key="key"
                class="mt-2 mb-2"
              >
                <a :href="banner.target_url" target="_blank"
                  ><img style="width: 100%" :src="banner.path"
                /></a>
              </div>

              <div
                class="title-bar text-center"
                style="margin: 0; padding: 10px"
              >
                <div class="title margin-right-auto" style="font-size: 30px">
                 {{ $t("experience.title") }}
                </div>
              </div>
              <div class="card-matches card-bg-blue">
                <!-- <div class="card-matches-title">2021-06-15</div> -->
                <div class="card-matches-content" style="overflow: auto">
                  <p>
                    <span><h3>{{ $t("experience.sub-title") }}:</h3></span>
                  </p>
                  <p>
                    {{ $t("experience.para-one") }}
                  </p>
                  <p>
                   {{ $t("experience.para-two") }}
                  </p>
                  <p>
                  {{ $t("experience.para-three") }}
                  </p>
                  <p>
                  {{ $t("experience.para-four") }}
                  </p>

                  <p>
                   {{ $t("experience.para-fifth") }}
                  </p>

                  <p>
                    <span><h3>{{ $t("experience.water-supply") }}</h3></span>
                  </p>

                  <p>
                  {{ $t("experience.para-water") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </body>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "Experience",
  components: {
    Header,
    Footer,
  },
  props: {
    msg: String,
  },
  computed: {
    bannerTop() {
      if (this.bannerSettings != null) {
        return this.bannerSettings.filter(function (item) {
          return item.category == 1;
          //return item;
        });
      } //

      return "";
    },
  },
  data() {
    return {
      bannerHost: "",
      currentHost: window.location.hostname,

      bannerSettings: [
        {
          title: "pc top",
          category: 1,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-顶部",
          path:
            "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
        {
          title: "72963-pc",
          category: 1,
          status: 1,
          target_url: "https://77577.com",
          cat_title: "电脑-顶部",
          path:
            "https://72963.com/uploads/images/20201214/banner-77577-desktop.png",
          width: 970,
          height: 150,
        },
      ],
    };
  },
  created() {
    axios
      .get(`${this.bannerHost}/api/banner`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
};
</script>

<style scope>
.card-matches p {
  font-size: 18px;
  word-spacing: 2px;
}

.card-matches h3 {
  font-weight: 600;
  text-shadow: 3px 5px 2px #474747;
}
body.body-recommend {
  background: url(/assets/img/bg-experience.png) repeat;
}

.card-matches-content p {
  line-height: 2;
  padding-bottom: 10px;
}
</style>
